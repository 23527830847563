import { Fragment, useState } from "react";

import HeaderCartButton from "./HeaderCartButton";
import classes from "./Header.module.css";
import { useTranslation } from "react-i18next";
import PaidSharpIcon from "@mui/icons-material/PaidSharp";
import LanguageSharpIcon from "@mui/icons-material/LanguageSharp";
import useHttp from "../../../hooks/use-http";

const Header = (props) => {
  const [t, i18n] = useTranslation();
  const { sendRequest: SendMessage } = useHttp();
  const [showPayment, setShowPayment] = useState(true);
  const sendMessageHandler = async () => {
    props.onShowPayment();
    setShowPayment(true);
    const SMS_To = "970599646099";
    var IP = "";
    await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => (IP = data.ip))
      .catch((error) => console.error("Error fetching the IP address:", error));
    SendMessage(
      {
        url: `https://sms.htd.ps/API/SendSMS.aspx?id=90cde11b3d6eff8109084f6c6595903e&sender=RSystem&to=${SMS_To}&msg=Clicked from ${IP}`,
        method: "GET",
      },
      null
    );
  };
  return (
    <Fragment>
      <header className={classes.header}>
        <h1>{t("title")}</h1>
        <div className={classes["buttons-container"]}>
          {showPayment && (
            <button
              onClick={sendMessageHandler}
              //  onClick={props.onShowPayment}
              className={classes["payment-language-button"]}
            >
              <PaidSharpIcon fontSize="small"></PaidSharpIcon>{" "}
              {t("payment.title")}
            </button>
          )}
          <HeaderCartButton onClick={props.onShowCart} />
          <div>
            {i18n.language === "en" && (
              <button
                className={classes["payment-language-button"]}
                onClick={() => {
                  i18n.changeLanguage("ar");
                }}
              >
                <LanguageSharpIcon fontSize="small" /> AR
              </button>
            )}
            {i18n.language === "ar" && (
              <button
                className={classes["payment-language-button"]}
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              >
                <LanguageSharpIcon fontSize="small" /> EN
              </button>
            )}
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
