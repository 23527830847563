import React, { useState } from "react";
import useHttp from "../../hooks/use-http";
import Checkout from "../Cart/Checkout";
import arabBankLogo from "../../../src/assets/arabbank-logo.png";
import bopLogo from "../../../src/assets/bop-logo.png";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";
import classes from "./Payment.module.css";
import { Button } from "@mui/material";
import * as CryptoJS from "crypto-js";
//import sign from "../../Security/Sign";

const Payment = (props) => {
  const [paymentId, setPaymentId] = useState("");
  let transactionReference;
  const { sendRequest: PostPayment } = useHttp();
  const { sendRequest: SendMessage } = useHttp();
  const [t] = useTranslation();
  const [error, setError] = useState(false);
  const [isSuccessPayment, setIsSuccessPayment] = useState(false);
  const [isCheckout, setIsCheckout] = useState(true);
  const [checkoutFormData, setCheckoutFormData] = useState({});
  const [isPaymentMethodsSelection, setIsPaymentMethodsSelection] =
    useState(false);
  const [cyperSourceData, setCyperSourceData] = useState({});
  const responseDataHandler = (responseData) => {
    setPaymentId(responseData["name"].substring(1));
    console.log();
  };
  const [signature, setSignature] = useState("");

  const savePaymentHanlder = (checkoutData) => {
    PostPayment(
      {
        url: "https://react-training-394f6-default-rtdb.firebaseio.com/payments.json",
        method: "POST",
        body: JSON.stringify({
          checkoutForm: checkoutData,
          transaction: transactionReference,
        }),
      },
      responseDataHandler
    );
  };
  const sendMessageHandler = (checkoutData) => {
    const SMS_CONTENT = `*حركة دفع*: -الاسم:${checkoutData.firstname} ${checkoutData.lastname} .. المبلغ :${checkoutData.amount} ${checkoutData.currency} .. جوال:${checkoutData.phone}`;
    const SMS_To = "970599646099";
    //970568348302
    SendMessage(
      {
        url: `https://sms.htd.ps/API/SendSMS.aspx?id=90cde11b3d6eff8109084f6c6595903e&sender=RSystem&to=${SMS_To}&msg=${SMS_CONTENT}`,
        method: "GET",
      },
      null
    );
  };
  const submitPaymentHandlerBOP = () => {
    console.log(checkoutFormData);
    try {
      const lahza = new window.LahzaPopup();
      lahza.newTransaction({
        key: "pk_live_Zl7XvgouBIGUzTUVxELQNypW7uMCgWApz",
        email: checkoutFormData.email,
        currency: checkoutFormData.currency,
        amount: checkoutFormData.amount * 100,
        mobile: checkoutFormData.phone,
        first_name: checkoutFormData.firstName,
        last_name: checkoutFormData.lastName,
        onSuccess: (transaction) => {
          transactionReference = transaction;
          savePaymentHanlder(checkoutFormData);
          sendMessageHandler(checkoutFormData);
          setIsSuccessPayment(true);
          setIsCheckout(false);
          setIsPaymentMethodsSelection(false);
        },
        onCancel: () => {},
      });
    } catch {
      setError(true);
    }
  };

  const submitPaymentHandlerArabi = () => {
    const cyperSource = {
      access_key: "c996c4fd49153ebc8990440f518f6603",
      profile_id: "BB6404FD-18E7-481A-8624-F44D873F1D26",
      transaction_uuid: `${Date.now() - 40}`,
      signed_field_names:
        "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_to_forename,bill_to_surname,bill_to_address_line1,bill_to_address_postal_code,bill_to_address_country,bill_to_email,bill_to_address_state,bill_to_address_city",
      unsigned_field_names: "",
      signed_date_time: generateFormattedDate(),
      locale: "en",
      transaction_type: "sale",
      reference_number: new Date().getTime(),
      amount: checkoutFormData.amount,
      currency: checkoutFormData.currency,
      bill_to_forename: checkoutFormData.firstname,
      bill_to_surname: checkoutFormData.lastname,
      bill_to_address_line1: "Ramallah",
      bill_to_address_postal_code: "900430",
      bill_to_address_country: "PS",
      bill_to_email: checkoutFormData.email,
      bill_to_address_state: "PS-RBH",
      bill_to_address_city: "Ramallah",
    };
    setCyperSourceData(cyperSource);

    const signedFieldNames = cyperSource.signed_field_names.split(",");
    let dataToSign = "";

    signedFieldNames.forEach((fieldName) => {
      dataToSign += `${fieldName}=${cyperSource[fieldName]},`;
    });

    const secretKey =
      "ca395a9ade2e4119bac480b538545c3276f663060a4548859169a1182e9235f6889e7d33b23e4c98a35d753128ddbddc9b98d8c1a5e544988bbd73b935676fda0e128a8f4aac4f9ba9ec01aabd63ded93fc3f775029644409aa5707222b052ae9fcc7fc8d4b349e5ae30c87f4c5da9f55a44b1bf4ef240bcb38b7ebc4f39c579";
    setSignature(signData(dataToSign.slice(0, -1), secretKey));
    setIsPaymentMethodsSelection(false);
  };

  function generateFormattedDate() {
    const date = new Date();

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }
  function signData(data, secretKey) {
    debugger;
    console.log(data);
    // Convert the secret key to a WordArray
    const secretKeyWA = CryptoJS.enc.Utf8.parse(secretKey);

    // Compute the HMAC SHA256
    const hmacSHA256 = CryptoJS.HmacSHA256(data, secretKeyWA);

    // Convert the HMAC SHA256 result to a base64-encoded string
    const base64Encoded = hmacSHA256.toString(CryptoJS.enc.Base64);

    console.log(base64Encoded);
    return base64Encoded;
  }

  const checkoutForm = (
    <React.Fragment>
      <div>
        <div className={classes["payment-title"]}>
          <p>{t("payment.title")}</p>
        </div>

        <Checkout
          onConfirm={(checkoutData) => {
            setIsPaymentMethodsSelection(true);
            setIsCheckout(false);
            debugger;
            setCheckoutFormData(checkoutData);
          }}
          onCancel={props.onClose}
          formMode="2"
        ></Checkout>
      </div>
    </React.Fragment>
  );
  const hasError = (
    <React.Fragment>
      <p>{t("checkoutForm.errorSendingOrder")}</p>
      <div className={classes.actions}>
        <button className={classes.button} onClick={props.onClose}>
          {t("cart.close")}
        </button>
      </div>
    </React.Fragment>
  );
  const successPayment = (
    <React.Fragment>
      <div className={classes.invoicement}>
        <h2>{t("invoicement.title")}</h2>
        <p>
          <strong> {t("invoicement.company")}</strong>
          {t("invoicement.companyName")}
        </p>
        <p>
          {" "}
          <strong> {t("invoicement.orderId")}</strong>
          {paymentId}
        </p>
        <p>
          <strong> {t("invoicement.customerName")}</strong>{" "}
          {checkoutFormData.firstname} {checkoutFormData.lastname}
        </p>
        <p>
          {" "}
          <strong>{t("invoicement.email")}</strong> {checkoutFormData.email}
        </p>
        <p>
          <strong>{t("invoicement.phone")}</strong> {checkoutFormData.phone}
        </p>
        <p>
          <strong>{t("invoicement.amount")}</strong> {checkoutFormData.amount}{" "}
          {checkoutFormData.currency}{" "}
        </p>
        <p>
          <strong>{t("invoicement.paidVia")} </strong>{" "}
          {t("invoicement.paidViaValue")}
        </p>
      </div>
      <p className={classes.note}>{t("invoicement.note")}</p>
      <div className={classes.actions}>
        <button className={classes.button} onClick={props.onClose}>
          {t("cart.close")}
        </button>
      </div>
    </React.Fragment>
  );

  const PaymentMethodsForm = (
    <React.Fragment>
      <div className={classes.paymentMethodsForm}>
        <div className={classes.paymentFormTextPhoto}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              debugger;
              submitPaymentHandlerArabi();
              savePaymentHanlder(checkoutFormData);
              sendMessageHandler(checkoutFormData);
            }}
          >
            {t("payment.arabi")}
          </Button>
          <img
            className={classes.arabbank}
            src={arabBankLogo}
            alt="Arab Bank logo"
          />
        </div>
        <div>
          <div className={classes.paymentFormTextPhoto}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => submitPaymentHandlerBOP()}
            >
              {t("payment.bop")}
            </Button>
            <img
              className={classes.bopbank}
              src={bopLogo}
              alt="Bank Of Palestine logo"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Modal onClose={props.onClose}>
      <div>
        {isCheckout && checkoutForm}
        {isPaymentMethodsSelection && PaymentMethodsForm}
        {!isCheckout && isSuccessPayment && successPayment}
        {error && hasError}

        {!isCheckout && !isPaymentMethodsSelection && (
          <form
            id="payment_confirmation"
            action="https://secureacceptance.cybersource.com/pay"
            method="post"
          >
            {Object.keys(cyperSourceData).map((name, index) => (
              <input
                type="hidden"
                id={name}
                name={name}
                value={cyperSourceData[name]}
                key={index}
              />
            ))}
            <input
              type="hidden"
              id="signature"
              name="signature"
              value={signature}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20rem",
              }}
            >
              <Button variant="contained" type="submit" id="submit">
                {t("payment.proceedPayment")}
              </Button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};
export default Payment;
