import { Fragment } from "react";

import AvailableOffers from "./AvailableOffers";

const Offers = () => {
  return (
    <Fragment>
      <AvailableOffers />
    </Fragment>
  );
};

export default Offers;
